import { Link, useParams } from "react-router-dom";
import { useState, } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {UserContext, JwtContext, LocationContext, ToastContext } from "../App";
import {  mapKey, host } from "../config";
import storedp from "../images/storedp.jpg";
import { getShareData } from "./store";

export default function Product() {
  const [product, setProduct] = useState(undefined);
  const [shareData, setShareData] = useState(undefined);
  const [liked, setLiked] = useState(false);
  const {user, setUser} = useContext(UserContext);
  const { jwt } = useContext(JwtContext);
  const {location} = useContext(LocationContext);
  const { notify } = useContext(ToastContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [modal, setModal] = useState(null);
  const [error, setError] = useState(undefined);
  if (error) throw error;

  let { id } = useParams();
  id = parseInt(id);

  useEffect(() => {
    setLoading(true);
    fetch(`${host}/public/products/${id}`)
      .then((res) => {
        if (!res.ok) throw res;

        return res.json();
      })
      .then((data) => setProduct(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));

  }, [id]);

  useEffect(() => {
    if (!product) return;

    getShareData(product.title, "Visit Yunistore to view Prduct", product.imageUrls[0] )
                    .then(setShareData);    
   
  }, [product]);

  useEffect(()=>{
    if (user) {
      setLiked(user.wishList.includes(id));
    }
  },[user])

  // useEffect(() => {
  //   return () => {
  //     if (modal) modal.hide();
  //   };
  // }, [modal]);

  const addToWishlist = () => {
    fetch(`${host}/user/wishlist?id=${id}`, {
      method: "post",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        if (!res.ok) throw res;
        user.wishList.push(id);
        setUser(user);
        setLiked(true);
        notify("added to wishList");
      })
      .catch((err) => setError(err));
  };

  const removeFromWishlist = () => {
  
    fetch(`${host}/user/wishlist?id=${id}`, {
      method: "delete",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        if (!res.ok) throw res;
        const i = user.wishList.indexOf(id);
        if(i>-1) user.wishList.splice(i,1);
        setUser(user);
        setLiked(false);
        notify("removed from wishlist");
      })
      .catch((err) => setError(err));
  };

  if (loading)
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center "
          style={{ height: "90vh" }}
        >
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );

  return (
    <section className="pb-4">
      <div className="container">
        <div className="row">
          <aside className="col-lg-6">
            <article className="gallery-wrap gallery-vertical sticky-lg-top  pt-2 pt-sm-4 ">
              <div className="img-big-wrap img-thumbnail mb-2 p-0">
                {/* <div className="" style={{ height: "500px ", overflow: "clip" }}> */}
                <img src={product.imageUrls[0]} id="main-img" alt="product" />
              </div>
              {/*********************  img-big-wrap***************************/}
              <div className="thumbs-wrap">
                {product.imageUrls.map((imgUrl,i) => (
                  <div
                    key={"img"+i}
                    className="item-thumb"
                    onClick={() =>
                      (document.querySelector("#main-img").src = imgUrl)
                    }
                  >
                    <img width="60" height="60" src={imgUrl} alt="product thumbnail"/>
                  </div>
                ))}
              </div>
              {/**************  thumbs-wrap*****************************/}
            </article>
            {/***************<!-- gallery-wrap .end// -->*********************/}
          </aside>
          <main className="col-lg-6 pt-lg-4 ">
            <div className="p-0">
              <div className="titte">
                <h4>
                  {product.title}
                  <br />
                </h4>
              </div>
              <div>
                <hr />
              </div>
              <div className="mt-1 text-secondary">
                <h6 className="mb-1 fs-7">
                  MRP -&nbsp;₹{product.mrp}
                  <br />
                </h6>
              </div>
              <div className="mt-1 text-primary">
                <h6 className="mb-1">Price -&nbsp;₹{product.price}</h6>
              </div>
              <div className="size"></div>
              <div>
                {product.quantity ? (
                  <h5 className="text-success">Instock</h5>
                ) : (
                  <h5 className="text-danger">Out of Stock</h5>
                )}
              </div>

              {/****************  STORE CARD   ********************/}
              <Link
                to={`/store/${product.store.id}`}
                className="card card-store-list mt-3"
              >
                <div className="row g-0">
                  <div className="col-4">
                    <div className="img-wrap" style={{ height: "100px" }}>
                      <img
                        alt="store"
                        src={product.store.thumbnail || storedp}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = storedp;
                        }}
                      />
                    </div>
                  </div>

                  {/***************<!-- col.// -->*****************/}
                  <div className="col-8">
                    <div className="card-body h-100 d-flex flex-column justify-content-between ps-0 pe-2 py-3">
                      <div>
                        <p href="#" className="title h5">
                          {product.store.name}
                        </p>
                        <p className="text-muted">{product.store.category}</p>
                      </div>
                    </div>
                    {/******************<!-- card-body.// -->**********************/}
                  </div>
                  {/****************<!-- col.// -->****************/}

                  {/****************<!-- col.// -->*****************/}
                </div>
                {/***************<!-- row.// -->****************/}
              </Link>
              {
                location && (
                  <div className="mt-2">
                    <iframe
                      title="map"
                      style={{"width": "100%"}}
                      height="400"
                      frameBorder="0" 
                      referrerPolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/directions?key=${mapKey}&destination=${product.store.ltd},${product.store.lng}&origin=${location.latitude},${location.longitude}`}
                      allowFullScreen>
                    </iframe>
                  </div>
                )
              }
              

              <div className="row g-2 mt-1">
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100"
                      type="button"
                      onClick={() =>{
                        let uri  = `https://www.google.com/maps/dir/?api=1&destination=${product.store.ltd},${product.store.lng}`;
                        window.open(encodeURI(uri), "_blank");
                        // modal.show();
                      } }
                    >
                      Open in Google Map
                    </button>
                  </div>
                  <div className="col-9">
                    <button
                      className="btn btn-primary w-100"
                      type="button"
                      onClick={() => {
                        if (jwt) liked ? removeFromWishlist() : addToWishlist();
                        else {
                          notify("Please Login");
                          navigate("/signin");
                        }
                      }}
                    >
                      {liked ? "Remove from Wishlist" : "Add to Wishlist"}
                    </button>
                  </div>
                  <div className="col-3">
                      <button className="btn btn-primary w-100" onClick={()=>navigator.share(shareData)} ><i className="bi bi-share-fill fs-6"></i></button>
                    </div>
              </div>
                  

              <div className="specs mt-4">
                <h5 className="mb-0">Specification-</h5>
                <div className="row gy-2 specs-row m-0">
                  {Object.keys(product.specs).map((key,i) => (
                    
                      <div key={"spec"+i} className="col col-6 col-md-4 spec-col ">
                        <div className="border-bottom h-100">
                          <p className="m-0 spec fw-bold">{key}</p>
                          <p className="value mb-0">{product.specs[key]}</p>
                        </div>
                      </div>
                    
                  ))}
                </div>
              </div>

              <div className="mt-4 pb-2 border-bottom">
                <h5>Category-</h5>
                <p className="mb-0">{product.category}</p>
              </div>

              {/* <div className="desc mt-4 pb-2 border-bottom">
                <h5>Description -</h5>
                <p className="text-wrap text-break">{product.description}</p>
              </div> */}
            </div>

            {/************   <!-- product-info-aside .// -->********/}
          </main>

          {/******************  <!-- col.// --> ********************/}
        </div>
        {/*********************<!-- row.// -->********************/}
      </div>
      {/*********************<!-- container .//  -->********************/}
      {/* <MapModal
        {...{
          modal,
          setModal,
          store: product.store,
        }}
      /> */}
    </section>
  );
}

// function MapModal(props) {
//   const mapContainer = useRef(null);
//   const mode = useRef({
//     street:
//       "https://api.tomtom.com/style/1/style/21.1.0-*?map=basic_main&traffic_incidents=incidents_day&traffic_flow=flow_relative0&poi=poi_main&key=p5U8MxTf8OGcluRN9TD3POVGMLtGAiis",
//     satellite:
//       "https://api.tomtom.com/style/1/style/*?map=2/basic_street-satellite&traffic_incidents=incidents_day&traffic_flow=flow_relative0&poi=2/poi_satellite&key=p5U8MxTf8OGcluRN9TD3POVGMLtGAiis",
//   });
//   const [mapMode, setMapMode] = useState("street");
//   const routeGeojson = useRef(null);
//   const ttMap = useRef(null);
//   const mapModal = useRef(null);
//   const { location } = useContext(LocationContext);

//   useEffect(() => {
//     mapModal.current.addEventListener("show.bs.modal", (event) => {
//       loadMap();
//     });
//     props.setModal(new Modal(mapModal.current));
//   }, []);

//   const loadMap = () => {

//     if(ttMap.current) return;

//     const current = [location.longitude, location.latitude];
//     const storeLocation = [props.store.lng, props.store.ltd];
//     // const current = [77.733788, 24.5701017];
//     var map = tt.map({
//       key: ttKey,
//       container: mapContainer.current,
//       dragPan: true,
//     });
//     map.addControl(new tt.FullscreenControl());
//     map.addControl(new tt.NavigationControl());
//     const geolocateControl = new tt.GeolocateControl({
//       trackUserLocation: true,
//       showAccuracyCircle: false,
//       positionOptions: { enableHighAccuracy: true },
//     });
//     map.addControl(geolocateControl);

//     ttMap.current = map;

//     mapModal.current.addEventListener("shown.bs.modal", (event) => {
//       map.resize();
//     });

//     const addMarkers = (feature) => {
//       var startPoint, endPoint;
//       if (feature.geometry.type === "MultiLineString") {
//         startPoint = feature.geometry.coordinates[0][0]; //get first point from first line
//         endPoint = feature.geometry.coordinates.slice(-1)[0].slice(-1)[0]; //get last point from last line
//       } else {
//         startPoint = feature.geometry.coordinates[0];
//         endPoint = feature.geometry.coordinates.slice(-1)[0];
//       }

//       // new tt.Marker({ element: createMarkerElement("start") })
//       //   .setLngLat(startPoint)
//       //   .addTo(map);
//       // new tt.Marker({ element: createMarkerElement("finish") })
//       let popup = new tt.Popup()
//         .setHTML(`<h6>${props.store.name}</h6>`)
//         .setOffset(30);
//       new tt.Marker().setLngLat(endPoint).setPopup(popup).addTo(map);
//       // .togglePopup();
//     };


//     map.once("load", () => {
//       services
//         .calculateRoute({
//           key: ttKey,
//           traffic: false,
//           locations: `${current[0]},${current[1]}:${storeLocation[0]},${storeLocation[1]}`,
//         })
//         .then((response) => {
//           var geojson = response.toGeoJson();
//           routeGeojson.current = geojson;
//           map.addLayer({
//             id: "route",
//             type: "line",
//             source: {
//               type: "geojson",
//               data: geojson,
//             },
//             paint: {
//               "line-color": "#4a90e2",
//               "line-width": 8,
//             },
//           });

//           addMarkers(geojson.features[0]);

//           // geolocateControl.trigger();
//           var bounds = new tt.LngLatBounds();
//           geojson.features[0].geometry.coordinates.forEach(function (point) {
//             bounds.extend(tt.LngLat.convert(point));
//           });
//           map.fitBounds(bounds, { duration: 0, padding: 50 });
//         });

//       map.on("styledata", () => {
//         if (map.getLayer("route")) return;
//         if (!routeGeojson.current) return;
//         let geojson = routeGeojson.current;
//         map.addLayer({
//           id: "route",
//           type: "line",
//           source: {
//             type: "geojson",
//             data: geojson,
//           },
//           paint: {
//             "line-color": "#4a90e2",
//             "line-width": 8,
//           },
//         });
//       });
//     });
//   };

//   return (
//     <div
//       className="modal fade"
//       id="locationModal"
//       tabIndex="-1"
//       aria-labelledby="exampleModalLabel"
//       aria-hidden="true"
//       ref={mapModal}
//       data-bs-backdrop="static"
//     >
//       <div className="modal-dialog modal-xl">
//         <div className="modal-content" style={{ height: "90vh" }}>
//           <div className="modal-header">
//             <h5 className="modal-title" id="exampleModalLabel">
//               TomTom
//             </h5>
//             <button
//               type="button"
//               className="btn-close"
//               data-bs-dismiss="modal"
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body position-relative p-0">
//             <div className="w-100 h-100">
//               <div ref={mapContainer} className="h-100 w-100"></div>
//             </div>
//             <button
//               className="btn btn-light text-capitalize m-1 position-absolute top-0 start-0"
//               onClick={() => {
//                 let style = mapMode === "street" ? "satellite" : "street";
//                 ttMap.current.setStyle(mode.current[style]);

//                 setMapMode(style);
//               }}
//             >
//               {mapMode + " mode"}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
