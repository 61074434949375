import "bootstrap/js/dist/dropdown";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {  LocationContext } from "../App";
import ProductGrid, { Placeholder } from "../component/productgrid";
import Pagination from "../component/pagination";
import Distance from "../component/distance";
import { host } from "../config";

export default function Category(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { location } = useContext(LocationContext);
  const [productLoading, setproductLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [products, setProducts] = useState(undefined);
  const [categoryData, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  if (error) throw error;


  const id = searchParams.get("id");
  const page = searchParams.get("page") || 1;
  const distance = searchParams.get("distance") || 10;

  const changeDistance = (newDistance)=>{
    navigate(`/category?id=${id}&distance=${newDistance}`)
  }


  const loadData = async ()=>{

    setCategoryLoading(true);

    let data = [];
    let categoryId = id;
    let category = await getCategory(categoryId);
    let childs = await getChilds(categoryId);
    
    if(childs.length){
      data.push({current : null, siblings: childs.map(mapCategory)})
    }
    categoryId = category.id;
    let arr =  category.name.split("/").reverse(); 
      
      for(let str of arr){

      let siblings = await getSiblings(categoryId);
     
      categoryId  = siblings[0].parentId;
      data.push(
        {
          current: str,
          siblings: siblings.map(mapCategory) })
      
    }

    data.reverse();

    setData(data);
    setCategoryLoading(false);

  }

  const changeCategory = async (index, category)=>{

    let childs = await getChilds(category.id);

    categoryData[index].current= category.name;

    if(childs.length){
      let ob  = {current : null, siblings : childs.map(mapCategory)}
      categoryData.splice(index+1, Infinity, ob);
    }
    else {
      categoryData.splice(index+1, Infinity);
    }

    setData([...categoryData]);

  }

  const mapCategory = c=>({
    id: c.id,
    name : c.name.split("/").pop(),
  }
);

  useEffect(()=>{
    loadData();
  },[])



  useEffect(() => {
    if (!id) return;
    if(!location) return;
    setproductLoading(true);
    getProducts(id, page,  location).then((products) => {
      setProducts(products);
      setproductLoading(false);
    });
  }, [id, page, distance, location]);


  // useEffect(() => console.log(categories), [categories]);

  const getCategory = (id) =>
    fetch(`${host}/public/categories/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .catch((err) => setError(err));

  const getChilds = (id) =>
    fetch(`${host}/public/categories/${id}/firstchilds`)
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .catch((err) => setError(err));

  const getSiblings = (id) =>
    fetch(`${host}/public/categories/${id}/siblings`)
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .catch((err) => setError(err));

  const getProducts = (id, page, location) => {
    const pageSize = 20;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("size", pageSize);
    formData.append("page", page - 1);
    formData.append("distance", distance);
    
    // formData.append("ltd", 24.5701017);
    formData.append("ltd", location.latitude);
    // formData.append("lng", 77.733788);
    formData.append("lng", location.longitude);

    return fetch(`${host}/public/products/bycategory`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) throw res;

        return res.json();
      })
      .catch((err) => setError(err));
  };

  const categoryNav = () => (
    <div className="bg-primary">
      <div className="container mb-1">
        <nav className="d-flex flex-wrap  py-2 ">
          {categoryLoading ? (
            <div className="text-white">Loading...</div>
          ) : (
            categoryData.map((cat, i) => (
            
                    <div className="d-flex" key={i}>
                    {!i || <p className="text-white inline mb-0 px-1">/</p>}
                    <div className="dropdown" >
                      <span
                        className="text-white dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {cat.current || "select"}
                      </span>
                      {
                        <ul
                          className="dropdown-menu"
                          style={{ overflow: "auto", maxHeight: "75vh" }}
                        >
                          {cat.siblings.map((category, index) => (
                            <li className="" key={index}>
                              <span
                                className="dropdown-item"
                                onClick={() => {
                                  changeCategory(i, category);
                                  navigate(`/category?id=${category.id}&distance=${distance}`);
                                }}
                              >
                                {category.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      }
                    </div>
                  </div>
            ))
          )}
        </nav>
      </div>
    </div>
  );

  // useEffect(() => console.log(products), [products]);

  return (
    <>
      {categoryNav()}
      {productLoading ? (
        <>
          <header className="bg-primary mb-4  text-white">
            <div className="container d-flex align-items-center justify-content-between">
              <span className="d-block py-2">Loading...</span>
            </div>
          </header>

          <div className="container">
            <Placeholder length="12" />
          </div>
        </>
      ) : (
        products && (
          <>
            <header className="bg-primary mb-4  text-white">
              <div className="container d-flex align-items-center justify-content-between">
                <div>

                <span className="d-block  py-2">
                  {products.totalElements}
                  {products.totalElements > 1 ? " Product's Found" : " Product Found"}
                </span>
                </div>
                <div>
                  <Distance distance = {distance} changeDistance={changeDistance}/>
                </div>
              </div>
            </header>
            {products.totalElements ? (
              <>
                <div className="container">
                  <ProductGrid products={products.content} />
                </div>
                <Pagination
                  currentPage={page}
                  changeCurrentPage={(pageNo) =>
                    navigate(`/category?id=${id}&page=${pageNo}&distance=${distance}`)
                  }
                  totalPages={products.totalPages}
                />
              </>
            ) : (
              <div
                className="d-flex align-items-center flex-column justify-content-center"
                style={{ height: "60vh" }}
              >
                <h3 className="">Sorry, no results found!</h3>
                <p className="fs-6 text-center">
                  Please select another category or try searching for something
                  else
                </p>
              </div>
            )}
          </>
        )
      )}
    </>
  );
}
