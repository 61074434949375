import {S3Client} from "@aws-sdk/client-s3";
import {fromCognitoIdentityPool} from "@aws-sdk/credential-providers";

const REGION = "ap-south-1";

export const s3Client = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: REGION }, // Configure the underlying CognitoIdentityClient.
    identityPoolId: 'ap-south-1:4d00d30a-6a9f-4d9e-86dc-30ab45a3bff4',
  })
});


export const bucket = "yunibucket-1";

export const endpoint = `https://s3.${REGION}.amazonaws.com/${bucket}`;

export const mapKey = "AIzaSyB5Ubby5NT6gZ9op0nLin_WSgIvKxNtWWI";


export const host = "https://api.yunistore.in";