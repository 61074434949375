import StoreProductGrid from "../component/storeproductgrid";
import storedp from "../images/storedp.jpg";
import UpdateProduct from "../component/updateproduct";
import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { JwtContext } from "../App";
import AddProduct from "../component/addproduct";
import InfiniteScroll from "react-infinite-scroller";
import { mapKey, host } from "../config";
import { getShareData } from "./store";

export default function ProfileStore() {
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [store, setstore] = useState(null);
  const [shareData, setShareData] = useState(undefined);
  const [editProductId, setEditId] = useState(null);
  const [updateModal, setUpdateModal] = useState(null);
  const [addModal, setAddModal] = useState(null);
  const navigate = useNavigate();
  const { jwt } = useContext(JwtContext);
  // const { location } = useContext(LocationContext);
  // const mapContainer = useRef(null);

  const [error, setError] = useState(undefined);
  if (error) throw error;

  useEffect(() => {
    if (!jwt) {
      navigate("/signin");
    }
    else
      fetchStore();
  }, [jwt]);

  useEffect(() => {
    if (!store) return;

    getShareData(store.name, "Visit Yunistore to view Profile", store.thumbnail )
                    .then(setShareData);    
   
  }, [store]);

  useEffect(() => {
    return () => {
      if (addModal && updateModal) {
        addModal.hide();
        updateModal.hide();
      }
    };
  }, [addModal, updateModal]);

  const fetchStore = () => {
    fetch(`${host}/user/store`, {
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        if (res.status === 404) {
          navigate("/account/nostore");
          return;
        }
        if (!res.ok) throw res;

        return res.json();
      })
      .then(setstore)
      .catch(setError);
  };

  const getProducts = async () => {
    return fetch(
      `${host}/user/store/products?page=${page}&size=20&sort=modifiedOn,desc`,
      {
        headers: { Authorization: "Bearer " + jwt },
      }
    )
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((data) => {
        setPage(page + 1);
        let arr = [...products, ...data.content];
        setProducts([...arr]);
        setHasMore(!data.last);
        // console.log(data.last);
        // console.log(page);
        return arr;
      })
      .catch((err) => setError(err));
  };

  const addProduct = (product) => {
    setProducts([product, ...products]);
  };

  const updateProduct = (product) => {
    setProducts(
      products.map((p) => {
        if (p.id === product.id) return product;
        return p;
      })
    );
  };

  const deleteProduct = (id) => {
    const product = products.find((p) => p.id === id);
    const index = products.indexOf(product);
    if (index > -1) products.splice(index, 1);
    setProducts([...products]);
  };

  if (!store)
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center "
          style={{ height: "90vh" }}
        >
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="py-3 ">
        {/************************* HEADER OF STORE *******************************/}
        <div className="mb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 ">
              <div className="row">
                <aside className="col-5 store bg-light">
                  <div className="img-wrap">
                    <img src={store.thumbnail || storedp} alt="store" />
                  </div>
                </aside>
                <div className="col-7 position-relative">
                  <div className="h-100 d-flex flex-column justify-content-around">
                    <div className="">
                      <h4>{store.name}</h4>
                      <h6>{store.category}</h6>
                      <a href={`tel:${store.contact}`}>{store.contact}</a>
                    </div>
                    <div className="row gx-1">
                      <div className="col-9">
                        <Link className="btn btn-secondary w-100"
                          to={"/account/store/edit"}
                        >Edit Details</Link>
                      </div>
                      <div className="col-3">
                        <button className="btn btn-primary w-100" onClick={()=>navigator.share(shareData)} ><i className="bi bi-share-fill fs-6"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3 d-md-none"></div>
            <div
              className="col-md-6 col-lg-6 "
            >
              <div className="position-relative"
              style={{ height: "180px" }}
              >
               
                  <iframe
                  title="map"
                  className="h-100 w-100"
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/directions?key=${mapKey}&destination=${store.ltd},${store.lng}&origin=${store.ltd},${store.lng}&zoom=16`}
                  allowFullScreen>
                </iframe>
              
                <div className="position-absolute h-100 w-100 top-0"
                onClick={() =>{
                  let uri  = `https://www.google.com/maps/dir/?api=1&destination=${store.ltd},${store.lng}`;
                  window.open(encodeURI(uri), "_blank");
                  // modal.show();
                  } }
                  ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/****************************END OF HEADER  *******************************/}
        <div className="bg-light p-0 mb-4 py-2 ">
          <div className="container d-flex align-items-center justify-content-between">
            <h5 className="d-block py-2 m-0">Products </h5>

            <button
              className="btn btn-primary py-1 m-0"
              onClick={() => addModal.show()}
            >
              Add Product
            </button>
          </div>
        </div>
        {/**************************END OF NAV *****************************/}
        <InfiniteScroll
          pageStart={0}
          loadMore={getProducts}
          hasMore={hasMore}
          loader={
            <>
              <div
                className="d-flex justify-content-center align-items-center "
                style={{ height: "250px" }}
              >
                <div className="spinner-border " role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          }
        >
          <div className="container">
            <StoreProductGrid 
              {...{
                setEditId,
                addModal,
                updateModal,
                products,
              }}
            />
          </div>
        </InfiniteScroll>
      </div>
      <UpdateProduct
        {...{
          updateModal,
          setUpdateModal,
          editProductId,
          fetchStore,
          updateProduct,
          deleteProduct,
        }}
      />
      <AddProduct {...{ addModal, setAddModal, fetchStore, addProduct }} />
      {/* <ProfileStoreEdit /> */}
    </>
  );
}
