
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { JwtContext, } from "../App";

export default function Account() {
  // console.log("in account");

  const navigate = useNavigate();
  const { jwt, } = useContext(JwtContext);
  const [navComp, setNavComp] = useState("");
  
  useEffect(() => {
    if (!jwt) navigate("/signin");
  },[jwt]);

  const arr = ["profile", "wishlist", "following", "store"];

  return (
    <>
      {/* <section className="bg-primary py-2">
        <div className="container">
          <ol className="breadcrumb ondark mb-0">
            <li className="breadcrumb-item">
              <span>Home</span>
            </li>
            <li className="breadcrumb-item">
              <span>Account</span>
            </li>
            <li
              className="breadcrumb-item text-capitalize active"
              aria-current="page"
            >
              {navComp}
            </li>
          </ol>
        </div>
      </section> */}
      <section className="py-lg-4 py-2 bg-light" style={{ minHeight: "80vh" }}>
        <div className="container">
          <div className="row">
            <aside className="pb-0 col-lg-2">
              {/*<!--  COMPONENT MENU LIST  -->*/}
              <nav className="nav flex-lg-column nav-pills mb-2">
                {arr.map((item) => (
                  <Link
                    to={`/account/${item}`}
                    className={
                      item === navComp
                        ? "nav-link text-capitalize active"
                        : "nav-link text-capitalize px-2"
                    }
                    key={item}
                  >
                    {item}
                  </Link>
                ))}
                
              </nav>
              {/*<!--   COMPONENT MENU LIST END .//   -->*/}
            </aside>
            <main className="col-lg-10 bg-white p-0">
              <Outlet context={{ setNavComp }} />
            </main>
          </div>
        </div>
      </section>
    </>
  );
}
