import { useContext, useEffect } from "react"
import { JwtContext, LocationContext, } from "../App"
import { useNavigate } from "react-router-dom";
import { host } from "../config";


export default function Demo(){

    const {location, setLocation} =  useContext(LocationContext);
    // const {setJwt}  = useContext(JwtContext);
    const navigate = useNavigate();


    useEffect(()=>{
        // let formdata = new FormData();
        // formdata.append("email", "yunistore.in@gmail.com");
        // formdata.append("password", "123456A@");

        // fetch(host+"/auth/signin", {
        //     method: "post",
        //     body: formdata
        // })
        // .then(res=>{
        //     // console.log(res);
        //     if(res.ok){
                
        //         res.text().then(jwt=>{
        //             // console.log(jwt);
        //             setJwt(jwt);               
        //           });
        //     }
        //     else throw res;
        // });
        
        setLocation({latitude:24.5754787, longitude:77.730401});

    }, [])

    useEffect(()=>{
      
      if(!location) return;

      if(location.latitude === 24.5754787 & location.longitude === 77.730401)
          navigate("/");

    }, [location])


    return (
        <>
        <div
          className="d-flex justify-content-center align-items-center "
          style={{ height: "100vh" }}
        >
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    )
}