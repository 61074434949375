import { useContext, useEffect, useState } from "react";
import { Outlet, useParams, Link } from "react-router-dom";
import storedp from "../images/storedp.jpg";
import ProductGrid, { Placeholder } from "../component/productgrid";
import InfiniteScroll from "react-infinite-scroller";
import { LocationContext, JwtContext, UserContext, ToastContext } from "../App";
import { useNavigate } from "react-router-dom";
import {  mapKey, host, bucket, s3Client } from "../config";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export default function Store() {
  const [store, setStore] = useState(undefined);
  const [following, setFollowing] = useState(false);
  const [shareData, setShareData] = useState(undefined);
  // const mapContainer = useRef(null);
  const {user, setUser} = useContext(UserContext);
  const {jwt} = useContext(JwtContext);
  const {location} = useContext(LocationContext);
  const {notify} = useContext(ToastContext);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  if (error) throw error;

  let { id } = useParams();
  id = parseInt(id);
  

  useEffect(() => {
    setLoading(true);
    fetch(`${host}/public/stores/${id}`)
      .then((res) => {
        if (!res.ok) throw res;

        return res.json();
      })
      .then((data) => setStore(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (!store) return;

    getShareData(store.name, "Visit Yunistore to view Profile", store.thumbnail )
                    .then(setShareData);    
   
  }, [store]);

  useEffect(()=>{
    if (user) {
      setFollowing(user.following.includes(id));
    }
  },[user])


  const getProducts = async () => {
    return fetch(
      `${host}/public/stores/${id}/products?page=${page}&size=20&sort=modifiedOn,desc`
    )
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((data) => {
        setPage(page + 1);
        let arr = [...products, ...data.content];
        setProducts([...arr]);
        setHasMore(!data.last);
        // console.log(data.last);
        // console.log(page);
        return arr;
      })
      .catch((err) => setError(err));
  };

  const addToFollowing = () => {
    fetch(`${host}/user/following?id=${id}`, {
      method: "post",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        if (!res.ok) throw res;
        user.following.push(id);
        setUser(user);
        setFollowing(true);
        notify("Followed");
      })
      .catch((err) => setError(err));
  };

  const removeFromFollowing = () => {
  
    fetch(`${host}/user/following?id=${id}`, {
      method: "delete",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        if (!res.ok) throw res;
        const i = user.following.indexOf(id);
        if(i>-1) user.following.splice(i,1);
        setUser(user);
        setFollowing(false);
        notify("Unfollowed");
      })
      .catch((err) => setError(err));
  };


  if (loading)
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center "
          style={{ height: "90vh" }}
        >
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );

  return (
    <>
    <section className="py-3">
      {/******  ******************* HEADER OF STORE *******************************/}
      <div className="mb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 ">
              <div className="row">
                <aside className="col-5 store bg-light">
                  {/* <Link to="image" > */}
                  <div className="img-wrap">
                    <img src={store.thumbnail || storedp} alt="store"
                    onClick={(event)=>{
                      event.target.requestFullscreen();
                    }} />
                  </div>
                  {/* </Link> */}
                </aside>
                <div className="col-7 position-relative">
                  <div className="h-100 d-flex flex-column justify-content-around">
                    <div className="">
                      <h4>{store.name}</h4>
                      <h6>{store.category}</h6>
                      <a href={`tel:${store.contact}`}>{store.contact}</a>
                    </div>
                    <div className="row gx-1">
                      <div className="col-9">
                        <button className="btn btn-primary w-100"
                        onClick={() => {
                          if (jwt) following ? removeFromFollowing() : addToFollowing();
                          else {
                            notify("Please Login");
                            navigate("/signin");
                          }
                        }}
                        >{following ? "Following" : "Follow"}</button>
                      </div>
                      <div className="col-3">
                        <button className="btn btn-primary w-100" onClick={()=>navigator.share(shareData)} ><i className="bi bi-share-fill fs-6"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3 d-md-none"></div>
            <div
              className="col-md-6 col-lg-6 "
            >
              <div className="position-relative"
              style={{ height: "180px" }}
              >
                {
                  location && (
                  <iframe
                  title="map"
                  className="h-100 w-100"
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/directions?key=${mapKey}&destination=${store.ltd},${store.lng}&origin=${store.ltd},${store.lng}&zoom=16`}
                  allowFullScreen>
                </iframe>
                )
                }
                <div className="position-absolute h-100 w-100 top-0"
                onClick={() =>{
                  let uri  = `https://www.google.com/maps/dir/?api=1&destination=${store.ltd},${store.lng}`;
                  window.open(encodeURI(uri), "_blank");
                  // modal.show();
                  } }
                  ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/****************************END OF HEADER  *******************************/}
      <div className="bg-light mb-4 py-2 ">
        <div className="container d-flex align-items-center justify-content-between">
        
        <h5 className="d-block py-2 m-0">Products </h5>
        </div>
      </div>
      {/**************************END OF NAV *****************************/}

      <InfiniteScroll
        pageStart={0}
        loadMore={getProducts}
        hasMore={hasMore}
        loader={<Placeholder length="4" />}
      >
        <div className="container">
          <ProductGrid products={products} />
        </div>
      </InfiniteScroll>
    </section>
    <Outlet context={{url: store.thumbnail}} />
    </>
  );
}

export async function getShareData(title, text, s3Url){

    const key = s3Url.split("/").pop();
    
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key
    });

    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });

    const blob = await fetch(url).then(res=>res.blob());
    
    const file = new File([blob], "image.jpeg", {type: "image/jpeg"});
    
    const data = {  
                    url: window.location,
                    title: title,
                    text: text,
                    files: [file],
                  }

    return data;
}