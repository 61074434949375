import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { JwtContext, LocationContext, ToastContext } from "../App";
import { Modal } from "bootstrap";
import {  mapKey, host } from "../config";
import { Loader  } from "@googlemaps/js-api-loader";
import { compressAndUpload } from "../component/addproduct";

export default function AddStore() {
  const mapContainer = useRef(null);
  const mapModal = useRef(null);
  const okButton = useRef(null);
  const [ltdLng, setLtdLng] = useState(null);
  const [modal, setModal] = useState(null);
  const { jwt } = useContext(JwtContext);
  const { location } = useContext(LocationContext);
  const { notify } = useContext(ToastContext);
  const navigate = useNavigate();
  const [error, setError] = useState(undefined);
  if (error) throw error;

  useEffect(() => {
    if (!jwt) setError(new Response({}, { status: 401 }));
  }, []);

  useEffect(() => {
    if (!modal) setModal(new Modal(mapModal.current));
    return () => {
      if (modal) modal.hide();
    };
  }, [modal]);

  useEffect(() => {
    const center = {lat: location.latitude, lng: location.longitude};

    const loader= new Loader({
      apiKey : mapKey,
      version: "weekly"
    })

    loader.load().then(async (google) => {
      const {Map} = await google.maps.importLibrary("maps");
      const {event} = await google.maps.importLibrary("core");
      const {Marker} = await google.maps.importLibrary("marker");
      const {InfoWindow} = await google.maps.importLibrary("maps")

      const map = new Map(mapContainer.current,{
        center,
        zoom: 18,
        mapId: "map"
      })

      const marker = new Marker({
        map: map, 
        position: center,
        title: "store",
        draggable : true,
      
      })

      const info = new InfoWindow({
        content: "Pin your Store"
      })
      info.open({
        anchor: marker,
        map: map.current
      });

      event.addListener(map, "center_changed", ()=>{
        marker.setPosition(map.getCenter());
      })

      
      okButton.current.addEventListener("click", (event) => {
        let latLng = marker.getPosition();
        setLtdLng({ lng: latLng.lng(), ltd: latLng.lat() });
        });
      
    
    })




  }, []);



  return (
    <section className="h-100 d-flex justify-content-center align-items-center">
      <div className="container py-5">
        <div className="card shadow mx-auto" style={{ maxWidth: "400px" }}>
          <div className="card-body">
            <h4 className="card-title mb-4">Store Details</h4>
            <Formik
              initialTouched={{ image: true }}
              initialValues={{
                image: undefined,
                name: "",
                category: "",
                contact: "",
                location: "",
              }}
              validationSchema={Yup.object({
                image: Yup.mixed().required("required"),
                name: Yup.string()
                  .min(5, "must have 5 or more characters")
                  .max(40, "must be 40 characters or less")
                  .required("required"),
                category: Yup.string()
                  .min(3, "must have 3 or more characters")
                  .required("required"),
                contact: Yup.string()
                  .matches("^[0-9]{10}$", "must be a 10 digit number")
                  .required("required"),
              })}
              
              onSubmit={async (values, actions) => {
                const { image, ...store } = values;

                const thumbnail = await compressAndUpload(image, 1000, 1000);

                const headers = {
                  "content-type": "application/json",
                  Authorization: "Bearer " + jwt,
                };


                fetch(host+"/user/store", {
                  method: "post",
                  body: JSON.stringify({ ...store, ...ltdLng, thumbnail }),
                  headers,
                })
                  .then((res) => {
                    if (res.ok) {
                      notify("Store added to your account");
                      navigate("/account/store");
                    } else throw res;
                  })
                  .catch((err) => setError(err));
                  
              }}
            >
              {(formik) => (
                <Form>
                  <div className="mb-3">
                    <label className="form-label">Image</label>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      name="imageFile"
                      onChange={(event) => {
                        formik.setFieldValue("image", event.target.files[0]);
                      }}
                    />

                    <ErrorMessage name="image">
                      {(msg) => <div className="invalid-field">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    {/* <input type="text" className="form-control" name="name" /> */}
                    <Field className="form-control" name="name" />
                    <ErrorMessage name="name">
                      {(msg) => <div className="invalid-field">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Category</label>
                    {/* <input
                      type="text"
                      className="form-control"
                      name="Category"
                    /> */}
                    <Field className="form-control" name="category" />
                    <ErrorMessage name="category">
                      {(msg) => <div className="invalid-field">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Contact number</label>
                    {/* <input
                      className="form-control"
                      placeholder=""
                      type="text"
                    /> */}
                    <Field type="tel" className="form-control" name="contact" />
                    <ErrorMessage name="contact">
                      {(msg) => <div className="invalid-field">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Location</label>
                    <button
                      type="button"
                      onClick={() => modal.show()}
                      className="btn btn-secondary w-100"
                    >
                      {ltdLng ? "located" : "locate on map"}
                    </button>
                    <Field
                      className="d-none"
                      name="location"
                      validate={() =>
                        ltdLng ? undefined : "please locate your store"
                      }
                    />
                    {ltdLng ? null : (
                      <ErrorMessage name="location">
                        {(msg) => <div className="invalid-field">{msg}</div>}
                      </ErrorMessage>
                    )}
                  </div>

                  <div className="mb-4">
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      disabled={formik.isSubmitting}
                    >
                      Add Store
                    </button>
                  </div>

                  <div className="mb-2">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        required
                      />
                      <span className="form-check-label">
                        I agree with Terms and Conditions
                      </span>
                    </label>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>


      </div>
      <div
        className="modal fade"
        id="locationModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={mapModal}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Google Map
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body position-relative p-0">
              <div className="w-100" style={{ height: "70vh" }}>
                <div ref={mapContainer} className="h-100 w-100"></div>
              </div>
              
            </div>
            <div className="modal-footer">
              <button
                ref={okButton}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
