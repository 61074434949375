import dress from "../icons/category-png-blue/dress.png";
import shirt from "../icons/category-png-blue/shirt.png";
import slippers from "../icons/category-svg-blue/slippers.svg";
import sofa from "../icons/category-svg-blue/sofa.svg";
import ball from "../icons/category-svg-blue/ball.svg";
import homeItem from "../icons/category-svg-blue/homeitem.svg";
import toy from "../icons/category-svg-blue/toy.svg";
import animal from "../icons/category-svg-blue/animal.svg";
import cpu from "../icons/category-svg-blue/cpu.svg";
import painting from "../icons/category-svg-blue/painting.svg";
import cutlery from "../icons/food-svg-blue/cutlery.svg";
import fix from "../icons/category-svg-blue/fix.svg";
import watch from "../icons/category-svg-blue/watch.svg";
import { Link } from "react-router-dom";

export default function Categories() {
  return (
    <section className="padding-top">
      <div className="container">
        <nav className="row gy-4 row-cols-xl-8 row-cols-md-6 row-cols-sm-4 row-cols-3">
          <div className="col">
            <Link to="/category?id=50636" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={dress} alt="dress" />
              </span>
              <span className="text"> Women's clothing</span>
            </Link>
          </div>

          <div className="col">
            <Link to="/category?id=200053" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={shirt} alt="shirt" />
              </span>
              <span className="text"> Men’s clothing </span>
            </Link>
          </div>

          <div className="col">
            <Link to="/category?id=200042" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={slippers} alt="slippers" />
              </span>
              <span className="text"> Footwear </span>
            </Link>
          </div>

          <div className="col">
            <Link to="/category?id=229891" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={watch} alt="cutlery" />
              </span>
              <span className="text"> Accessories</span>
            </Link>
          </div>

          <div className="col">
            <Link to="/category?id=222386" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={sofa} alt="sofa" />
              </span>
              <span className="text"> Furniture </span>
            </Link>
          </div>

          <div className="col">
            <Link to="/category?id=200066" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={ball} alt="ball" />
              </span>
              <span className="text"> Sport and Fitness </span>
            </Link>
          </div>

          <div className="col d-none d-sm-block">
            <Link to="/category?id=200087" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={homeItem} alt="appliances" />
              </span>
              <span className="text"> Appliances </span>
            </Link>
          </div>


          {/* <div className="col">
            <Link to="category?id=200102" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={car} />
              </span>
              <span className="text"> Automotive </span>
            </Link>
          </div> */}

          <div className="col d-none d-sm-block">
            <Link to="/category?id=225529" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={painting} alt="painting" />
              </span>
              <span className="text"> Artwork & Painting </span>
            </Link>
          </div>

          <div className="col d-none d-md-block">
            <Link to="/category?id=200069" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={toy} alt="toy" />
              </span>
              <span className="text"> Kid's toys </span>
            </Link>
          </div>

          <div className="col d-none d-md-block">
            <Link to="/category?id=200212" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={animal} alt="animal" />
              </span>
              <span className="text"> Pet items </span>
            </Link>
          </div>

          <div className="col d-none d-md-block">
            <Link to="/category?id=200094" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={cpu} alt="cpu" />
              </span>
              <span className="text"> Computers & Laptops </span>
            </Link>
          </div>

          <div className="col d-none d-md-block">
            <Link to="/category?id=224005" className="item-link text-center">
              <span className="icon mb-2 icon-md rounded">
                <img width="32" height="32" src={fix} alt="fix" />
              </span>
              <span className="text">Hardware</span>
            </Link>
          </div>
        </nav>
      </div>
    </section>
  );
}
