import { Formik, Field, Form, ErrorMessage, } from "formik";
import { useContext } from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import reverse from "../icons/reverse.svg"
import * as Yup from "yup";
import { JwtContext,  ToastContext } from "../App";
import { Modal, } from "bootstrap";
import {s3Client, bucket, host, endpoint} from "../config";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { fromTokenFile } from "@aws-sdk/credential-providers";


export default function AddProduct(props) {
  const { jwt } = useContext(JwtContext);
  const { notify } = useContext(ToastContext);
  const modal = useRef(null);
  const categoryDropdown = useRef(null);
  const [error, setError] = useState(null);
  if (error) throw error;

  useEffect(() => {
    props.setAddModal(new Modal(document.getElementById("add-product-modal")));
  }, []);

  const MyFormik = (formikProps) => (
    <Formik
      initialTouched={{ images: true, specs: true }}
      initialStatus={{ specLength: 2 }}
      initialValues={{
        images: undefined,
        title: "",
        mrp: 1,
        price: 1,
        quantity: 0,
        category: "",
        specs: {},
        // description: "",
      }}
      validationSchema={Yup.object({
        images: Yup.mixed().test(
          "images-count",
          "select at least 2 images",
          (value) => {
            if (!value) return false;
            if (value instanceof Array && value.length > 1) return true;
            return false;
          }
        )
        // .test("images-size","image size must be less then 512KB",
        // (value)=>{
        //      if(value instanceof FileList){
        //       for( const image of value)
        //           if(image.size > 512000) return false;
        //      } 
        //      return true;
        // })
        ,
        title: Yup.string()
          .min(8, "must be 8 characters or more")
          .required("Title needed"),
        mrp: Yup.number().positive("must be more than 0").required("required"),

        quantity: Yup.number().min(0, "must be 0 or more").required("required"),
        category: Yup.string()
          .min(2, "must be 2 or more characters")
          .required("required"),
        // description: Yup.string()
        //   .min(20, "must be 20 characters or more")
        //   .required("required"),
      })}

      onSubmit={async (values, actions) => {
        const headers = {
          "content-type": "application/json",
          Authorization: "Bearer " + jwt,

        };
        
        const { images, ...product } = values;
        const { specs } = product;

        const newSpecs = {};
        
        Object.keys(specs).forEach((key) => {
          let index;

          if (key.startsWith("spec")) {
            let spec = specs[key];
            if (!spec) return;

            index = key.split("_")[1];

            let value = specs[`value_${index}`];
            if (!value) return;
            
            newSpecs[spec] = value;
          }
        });
        
                if (Object.keys(newSpecs).length < 2) {
                  actions.setFieldError("specs", "provide at least 2 specifications");
                  actions.setSubmitting(false);
                  return;
                }

       
        const thumbnail = await compressAndUpload(images[0], 500, 500);
        
        const imageUrls  = await Promise.all([...images]
          .map(image => compressAndUpload(image, 1500, 1500) 
          ));
        


        // const form = new FormData();

        // let i = 0;
        // for (const image of images) {
        //   form.append(`images_${i}`, image);
        //   i++;
        // }

        // form.append("product", JSON.stringify({ ...product, thumbnail, imageUrls, specs: newSpecs }));

        await fetch(host+"/user/store/products", {
          method: "post",
          // body: form,
          body:  JSON.stringify({ ...product, thumbnail, imageUrls, specs: newSpecs }),
          headers,
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else throw res;
          })
          .then((product) => {
            product.imageUrl = product.imageUrls[0];
            props.addProduct(product);
            props.addModal.hide();
            notify("Product Added");
            actions.resetForm();
          })
          .catch((err) => setError(err))
          // .finally(() => {
          //   actions.setSubmitting(false);
          // });
      }
    }
    >
      {formikProps.children}
    </Formik>
  );

  return (
    <div
      ref={modal}
      className="modal fade"
      id="add-product-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <MyFormik>
          {(formik) => (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Product details</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Form id="add-product-form">
                  <div className=" mb-2">
                    <article className="card-body p-0">
                      <div className="row mb-2">
                        <label className="col-3 form-label">Images</label>
                        <div className="col-9">
                          <label
                            htmlFor="images"
                            className="btn btn-secondary w-100"
                            onBlur={(event) => {
                              formik.setFieldTouched("images");
                            }}
                          >
                            Select images
                          </label>

                          <input
                            id="images"
                            name="images"
                            type="file"
                            className="form-control d-none"
                            accept="image/*"
                            multiple
                            onChange={(event) => {
                              const files = event.currentTarget.files;
                              const value =  files.length ? Array.from(files) : undefined   
                              formik.setFieldValue(
                                "images",
                                value
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {/* <Field
                            type="file"
                            name="images"
                            id="images"
                            className="form-control d-none"
                            accept="images/*"
                            multiple={true}
                          /> */}
                          <ErrorMessage name="images">
                            {(msg) => (
                              <div className="invalid-field">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                        {formik.values.images && (
                        <div className="row m-0 mt-2" id="previewdiv">
                          {formik.values.images.map((file, i) => (
                            <div
                              key={"img" + i}
                              className="img-div border col-4"
                            >
                              <img
                                src={URL.createObjectURL(file)}
                                alt="product"
                              />
                            </div>
                          ))}
                          <div className="col-4 m-auto text-center ">
                            <img className="my-3 mx-auto" 
                            src={reverse}
                            onClick={()=>{formik.setFieldValue("images",formik.values.images.reverse())}} 
                            alt="" 
                            height={32} width={32} />
                          </div>
                        </div>)}
                      </div>

                      <div className="row mb-2">
                        <label className="col-12 col-sm-3 col-form-label">
                          Title
                        </label>
                        <div className="col-12 ">
                          {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Type here"
                        /> */}
                          <Field
                            name="title"
                            className="form-control"
                            placeholder="Type here"
                          />
                          <ErrorMessage name="title">
                            {(msg) => (
                              <div className="invalid-field">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <label className="col-4 col-form-label">MRP</label>
                        <div className="col-8">
                          {/* <input
                          type="text"
                          className="form-control"
                          placeholder="₹"
                        /> */}
                          <Field
                            className="form-control"
                            placeholder="₹"
                            name="mrp"
                            type="number"
                          />
                          <ErrorMessage name="mrp">
                            {(msg) => (
                              <div className="invalid-field">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <label className="col-4 col-form-label">Price</label>
                        <div className="col-8">
                          {/* <input
                          type="text"
                          className="form-control"
                          placeholder="₹"
                        /> */}
                          <Field
                            className="form-control"
                            placeholder="₹"
                            name="price"
                            type="number"
                            validate={(value) => {
                              if (value < 0) return "price must be more than 0";
                              if (value > formik.values.mrp)
                                return "price must be less than MRP";
                            }}
                          />
                          <ErrorMessage name="price">
                            {(msg) => (
                              <div className="invalid-field">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <label className="col-4 col-form-label">Quantity</label>
                        <div className="col-8">
                          {/* <input
                          type="number"
                          className="form-control"
                          placeholder="0"
                        /> */}
                          <Field
                            className="form-control"
                            name="quantity"
                            type="number"
                          />
                          <ErrorMessage name="quantity">
                            {(msg) => (
                              <div className="invalid-field">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <label className="col-12 col-sm-3 col-form-label">
                          Category
                        </label>
                        <div className="col-12  ">
                          {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Type here"
                        /> */}
                          <div className="btn-group w-100">

                          <Field
                            className="form-control"
                            onFocus={()=>{
                              // console.log(categoryDropdown);
                              categoryDropdown.current.classList.add("show");
                            }}
                           
                            name="category"
                            list="categories"
                            placeholder="Type here"
                            onChange={(event) => {
                              let value = event.target.value.trim();
                              if (
                                value.length > 2 &&
                                event.nativeEvent.inputType === "insertText"
                                ) {
                                  fetch(
                                  `${host}/public/categories/search?query=${encodeURIComponent(
                                    value
                                  )}&limit=15`
                                )
                                  .then((res) => {
                                    if (res.ok) return res.json();
                                    throw res;
                                  })
                                  .then((list) => {
                                    let options = list.sort((a, b)=>a.name.length-b.name.length
                                    ).map((op) => {
                                      let el = document.createElement("li");
                                      el.className = "dropdown-item";
                                      el.innerHTML = op.name;
                                      el.addEventListener("click", ()=>{
                                        formik.setFieldValue("category", op.name);
                                        categoryDropdown.current.classList.remove("show");
                                      })
                                      return el;
                                    });

                                      categoryDropdown.current.replaceChildren(...options);
                                  })
                                  .catch((err) => setError(err));
                              }
                              
                              formik.setFieldValue(
                                "category",
                                event.target.value
                                );
                            }}
                          />
                         
                           </div>
                          {/* <datalist id="categories" ref={dataList}></datalist> */}
                          
                            <ul ref={categoryDropdown} className="dropdown-menu category-dropdown overflow-auto ">
                            
                            
                          </ul>
                          <ErrorMessage name="category">
                            {(msg) => (
                              <div className="invalid-field">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <label className="col-12 col-sm-3 col-form-label">
                          Specs
                        </label>
                        <div className="col-12 ">
                          {[...Array(formik.status.specLength).keys()].map(
                            (i) => (
                              <div
                                key={i}
                                className="d-flex align-items-center my-1"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="property"
                                  onChange={(event) => {
                                    const { specs } = formik.values;
                                    specs[`spec_${i}`] = event.target.value;
                                    formik.setFieldValue("specs", specs);
                                  }}
                                />

                                <span>&nbsp;:&nbsp;</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="value"
                                  onChange={(event) => {
                                    const { specs } = formik.values;
                                    specs[`value_${i}`] = event.target.value;
                                    formik.setFieldValue("specs", specs);
                                  }}
                                />
                              </div>
                            )
                          )}
                          <ErrorMessage name="specs">
                            {(msg) => (
                              <div className="invalid-field pb-1">{msg}</div>
                            )}
                          </ErrorMessage>
                          <div className="pb-2">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                let { specLength } = formik.status;
                                specLength++;
                                formik.setStatus({
                                  ...formik.status,
                                  specLength,
                                });
                              }}
                            >
                              add more
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row mb-2">
                        <label className="col-12 col-sm-3 col-form-label">
                          Description
                        </label>
                        <div className="col-12 ">
                          {/* <textarea
                          className="form-control"
                          placeholder="Type here"
                          rows="5"
                        ></textarea> 
                          <Field
                            name="description"
                            className="form-control"
                            placeholder="Type here"
                            as="textarea"
                            rows="5"
                          />
                          <ErrorMessage name="description">
                            {(msg) => (
                              <div className="invalid-field">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div> */}
                    </article>
                  </div>
                </Form>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  id="add-form-submit"
                  type="submit"
                  className="btn btn-primary px-4"
                  form="add-product-form"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Add Product"
                  )}
                </button>
              </div>
            </div>
          )}
        </MyFormik>
      </div>
    </div>
  );
}

export const  compressAndUpload = async (image, width, height)=>{

  let key = Math.random().toString(36).slice(2);

  return compressImage(image, width, height)
  .then(blob=> uplaodImage(blob, key))
  .then(()=> endpoint+"/"+key);
}

function  compressImage(file, width, height){

  return new Promise((resolve, reject)=>{

    // console.log(readableBytes(file.size));
  const blobUrl = URL.createObjectURL(file);
  const img = new Image();
  img.src = blobUrl;

  img.onload = ()=>{
    URL.revokeObjectURL(img.src);
    const [newWidth, newHeight] = calculateSize(img, width, height);
    const canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    
    canvas.toBlob(blob =>{
      console.log(readableBytes(blob.size));
      resolve(blob);
    },
    "image/jpeg", 0.8);
    
  }
  
});
}

function  uplaodImage(blob, key){

  const params = {
    Bucket: bucket,
    Key: key,
    Body: blob,
    ContentType: blob.type
  }

  return s3Client.send(new PutObjectCommand(params));

}


function calculateSize(img, maxWidth, maxHeight){
  let width = img.width;
  let height = img.height;

  if(width > height){
    if(width > maxWidth){
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if(height > maxHeight){
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  // console.log({width, height});
  return [width, height];
}

function readableBytes(bytes) {
  const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}