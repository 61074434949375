import { Outlet, ScrollRestoration } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import ToastAlert from "./component/toastAlert";
import { Toast } from "bootstrap";
import { host } from "./config";

export const UserContext = createContext(undefined)
export const LocationContext = createContext(undefined);
export const JwtContext = createContext(undefined);
export const SessionContext = createContext(undefined);
export const ToastContext = createContext(undefined);

export default function App() {
  const [location, setLocation] = useState(null);
  const [user, setUser] = useState(undefined);
  const [jwt, setJ] = useState(sessionStorage.getItem("jwt"));
  const [token, setT] = useState(sessionStorage.getItem("sessionToken"));
  const [toast, setToast] = useState(undefined);


  // const setLocation = (latLng) => {
  //   // console.log(latLng);
  //   // JSON.parse(sessionStorage.getItem("location"))
  //   sessionStorage.setItem("location", JSON.stringify(latLng));
  //   setL(latLng);
  // };

  const setJwt = (jwt) => {
    sessionStorage.setItem("jwt", jwt);
    setJ(jwt);
  };
  
  
  const setToken = (t) => {
    sessionStorage.setItem("sessionToken", t);
    setT(t);
  };


  const notify = (msg) => {
    setToast({ ...toast, msg });
    toast.instance.show();
    // console.log(toast);
  };

  useEffect(() => {
    if (toast) return;
    setToast({
      msg: "",
      instance: new Toast(document.getElementById("toast")),
    });
  });

  useEffect(()=>{
    
    if(!jwt) return setUser(undefined);

    const getData = async()=>{

        const headers = { Authorization: "Bearer " + jwt };

        const wishList = await  fetch(`${host}/user/wishlist`, {headers}).then(res=>res.json())
                                    .then(data=>data.map(p=>p.id));
                                    
        const following = await  fetch(`${host}/user/following`, {headers}).then(res=>res.json())
                                        .then(data =>data.map(s=>s.id));
        // console.log(wishList);
        setUser({wishList, following});

                                }
    getData();

  },[jwt])



  return (
    <>
      <UserContext.Provider value={{user, setUser}}>
        <LocationContext.Provider value={{ location, setLocation }}>
          <JwtContext.Provider value={{ jwt, setJwt }}>
            <SessionContext.Provider value={{ token, setToken }}>
                <ToastContext.Provider value={{ notify }}>
                  <Outlet />
                  <ScrollRestoration />
                  <ToastAlert msg={toast && toast.msg} />
                </ToastContext.Provider>
            </SessionContext.Provider> 
          </JwtContext.Provider>
        </LocationContext.Provider>
      </UserContext.Provider>
    </>
  );
}
