import { Formik, Form, Field, ErrorMessage, } from "formik";
import { useContext, useState, useRef } from "react";
import { useEffect } from "react";
import { useNavigate,  useOutletContext } from "react-router-dom";
import { JwtContext, ToastContext} from "../App";
import * as Yup from "yup";
import { mapKey, host } from "../config";
import { Loader } from "@googlemaps/js-api-loader";
import { Modal } from "bootstrap";
import { compressAndUpload } from "./addproduct";

export function StoreEdit() {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapModal = useRef(null);
  const okButton = useRef(null);
  const [ltdLng, setLtdLng] = useState(null);
  const [modal, setModal] = useState(null);
  const { jwt } = useContext(JwtContext);
  const { notify } = useContext(ToastContext);

  
  const { setNavComp } = useOutletContext();
  const navigate = useNavigate();
  const [store, setStore] = useState(undefined);
  const [error, setError] = useState(undefined);
  if (error) throw error;

  useEffect(() => {
    setNavComp("store");
    if (jwt) fetchStore();
    
  }, []);

  const fetchStore = () => {
    fetch(host+"/user/store", {
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        if (res.status === 404) {
          navigate("/profile/nostore");
          return;
        }
        if (!res.ok) throw res;

        return res.json();
      })
      .then((store) => setStore(store))
      .catch((err) => setError(err));
  };

    useEffect(()=>{
      
      if(!store) return;

      mapModal.current.addEventListener("show.bs.modal", (event) => {
        loadMap();})
      setModal(new Modal(mapModal.current));
    },[store])


    useEffect(() => {
      
      return () => {
        if (modal) modal.hide();
      };
    }, [modal]);

  const loadMap = ()=>{

    if(!store) return;
    if(map.current) return;

    const center = {lat: store.ltd, lng: store.lng};

    const loader= new Loader({
      apiKey : mapKey,
      version: "weekly"
    })

    loader.load().then(async (google) => {
      const {Map} = await google.maps.importLibrary("maps");
      const {event} = await google.maps.importLibrary("core");
      const {Marker} = await google.maps.importLibrary("marker");

      map.current = new Map(mapContainer.current,{
        center,
        zoom: 18,
        mapId: "map"
      })

      const marker = new Marker({
        map: map.current, 
        position: center,
        title: "store",
        draggable : true,
      
      })

      event.addListener(map, "center_changed", ()=>{
        marker.position = map.current.getCenter();
      })

      
    okButton.current.addEventListener("click", (event) => {
      let latLng = marker.getPosition();
      setLtdLng({ lng: latLng.lng(), ltd: latLng.lat() });
      });
    
    })

  }

  if (!store)
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );

  return (
    <>
    <div className="container py-2">
      <div className="row">
        <div className="col-sm-8 col-md-6 px-4">
          <Formik
            initialValues={{...store, image: ""}}
            validationSchema={Yup.object({
            //   image: Yup.mixed().notRequired().test('image-size',"image size must be less then 512KB",
            //   value=>{
            //     if(value) return !(value.size>512000);
            //     return true;
            // }),
              name: Yup.string()
                .min(5, "must have 5 or more characters")
                .max(40, "must be 40 characters or less")
                .required("required"),
              category: Yup.string()
                .min(3, "must have 3 or more characters")
                .required("required"),
              contact: Yup.string()
                .matches("^[0-9]{10}$", "must be a 10 digit number")
                .required("required"),
            })}
            onSubmit={async (values, actions) => {

              let { image, ...store } = values;

              if(ltdLng)
               store = { ...store, ltd: ltdLng.ltd, lng: ltdLng.lng,};

              if(image){
                const thumbnail = await compressAndUpload(image, 600, 600);
                store.thumbnail = thumbnail;
              }

              const headers = {
                "content-type": "application/json",
                Authorization: "Bearer " + jwt,
              };

              

              fetch(host+"/user/store", {
                method: "put",
                body: JSON.stringify({ ...store }),
                headers,
              })
                .then((res) => {
                  if (res.ok) {
                    notify("Store updated");
                    navigate("/account/store");
                  } else throw res;
                })
                .catch((err) => setError(err))
                .finally(() => actions.setSubmitting(false));
            }}
          >
            {(formik) => (
              <Form>
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    name="image"
                    onChange={(event) => {
                      formik.setFieldValue("image", event.target.files[0]);
                    }}
                  />

                  <ErrorMessage name="image">
                    {(msg) => <div className="invalid-field">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="mb-3">
                  <label className="form-label">Name</label>
                  {/* <input type="text" className="form-control" name="name" /> */}
                  <Field className="form-control" name="name" />
                  <ErrorMessage name="name">
                    {(msg) => <div className="invalid-field">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="mb-3">
                  <label className="form-label">Category</label>
                  {/* <input
                    type="text"
                    className="form-control"
                    name="Category"
                  /> */}
                  <Field className="form-control" name="category" />
                  <ErrorMessage name="category">
                    {(msg) => <div className="invalid-field">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="mb-3">
                  <label className="form-label">Contact number</label>
                  {/* <input
                    className="form-control"
                    placeholder=""
                    type="text"
                  /> */}
                  <Field type="tel" className="form-control" name="contact" />
                  <ErrorMessage name="contact">
                    {(msg) => <div className="invalid-field">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="mb-3">
                    <label className="form-label">Location</label>
                    <button
                      type="button"
                      onClick={() => modal.show()}
                      className="btn btn-secondary w-100"
                    >
                      {ltdLng ? "location changed" : "change location"}
                    </button>
                    <Field
                      className="d-none"
                      name="location"
                      // validate={() =>
                      //   ltdLng ? undefined : "please locate your store"
                      // }
                    />
                   
                  </div>
                <div className="mb-4">
                  <button
                    type="submit"
                    className="btn btn-primary px-4"
                    disabled={(formik.isSubmitting || !(formik.dirty || ltdLng)) }
                  >
                    Save Changes
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <hr className="mt-2 mb-4" />

      <div className="row">
        <div className="col-md-6">
          <article className="box mb-3 bg-light">
            <button
              className="btn float-end btn-outline-danger btn-sm"
              disabled
              onClick={() => {
                if (window.confirm("Are you sure to Remove Your Store")) {
                  fetch(host+"/user/store", {
                    method: "delete",
                    headers: { Authorization: "Bearer " + jwt },
                  })
                    .then((res) => {
                      if (res.ok) {
                        notify("Store Removed from Account");
                        navigate("/");
                      }
                      throw res;
                    })
                    .catch((err) => setError(err));
                }
              }}
            >
              Remove
            </button>
            <p className="title mb-0">Remove Store</p>
            <small className="text-muted d-block" style={{ width: "70%" }}>
              Once you delete your Store, there is no going back.
            </small>
          </article>
        </div>
      </div>
    </div>
     <div
     className="modal fade"
     id="locationModal"
     tabIndex="-1"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true"
     ref={mapModal}
   >
     <div className="modal-dialog modal-lg">
       <div className="modal-content">
         <div className="modal-header">
           <h5 className="modal-title" id="exampleModalLabel">
             Google Map
           </h5>
           <button
             type="button"
             className="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
           ></button>
         </div>
         <div className="modal-body position-relative p-0">
           <div className="w-100" style={{ height: "70vh" }}>
             <div ref={mapContainer} className="h-100 w-100"></div>
           </div>
          
         </div>
         <div className="modal-footer">
           <button
             ref={okButton}
             type="button"
             className="btn btn-primary"
             data-bs-dismiss="modal"
           >
             OK
           </button>
         </div>
       </div>
     </div>
   </div>
   </>
  );
}
