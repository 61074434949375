import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { JwtContext, UserContext } from "../App";
import { host } from "../config";
import StoreList from "./storeList";

export default function Following() {
  const { setNavComp } = useOutletContext();
  const { jwt } = useContext(JwtContext);
  const [stores, setStores] = useState(null);
  const {user, setUser} = useContext(UserContext);
  const [error, setError] = useState(null);
  if (error) throw error;

  useEffect(() => {
    setNavComp("following");
    getStores();
  }, []);

  const getStores = () => {
    if (!jwt) return;

    fetch(`${host}/user/following`, {
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        if (!res.ok) throw res;

        return res.json();
      })
      .then(setStores)
      .catch((err) => setError(err));
  };

  if (!stores)
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center "
          style={{ height: "40vh" }}
        >
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );

  if (!stores.length)
    return (
      <div
        className="d-flex align-items-center flex-column justify-content-center"
        style={{ height: "40vh" }}
      >
        <h4 className="">Sorry, Empty Following!</h4>
      </div>
    );

  return (
    <div className="container py-3">
      <StoreList stores={stores} />
    </div>
  );
}
