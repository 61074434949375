import { useEffect, useState } from "react";
import { useContext } from "react";
import { Formik, Form, Field } from "formik";
import {
  Link,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import { JwtContext, ToastContext } from "../App";
import { host } from "../config";

export default function Signin() {
  const { jwt, setJwt } = useContext(JwtContext);
  const { setNavComp } = useOutletContext();
  const [invalid, setInvalid] = useState(false);
  const [error, setError] = useState(undefined)
  const { notify } = useContext(ToastContext);
  if(error) throw error;

  const navigate = useNavigate();

  useEffect(() => setNavComp("signin"), []);


  useEffect(()=>{
    if(jwt){
      navigate(-1, {replace: true});
      // console.log("jwt changed");
    } 
      
  },[jwt])

  return (
    <section className="h-100 d-flex justify-content-center align-items-center">
     <div className="container  py-5">

        <div className="card shadow mx-auto" style={{ maxWidth: "400px" }}>
          <div className="card">
            <div className="card-body">
              <h4 className="mb-4">Sign in</h4>
              <Formik
                initialValues={{email: "", password: ""}}
                onSubmit={async(values, action)=>{
                
                 const formData = new FormData();
                 formData.append("email", values.email);
                 formData.append("password", values.password)
                 const res = await fetch(host+"/auth/signin", {
                    method: "post",
                    body: formData,
                  });

                  if(res.ok){
                    const token = await res.text();
                    setJwt(token);
                    notify("Logged in");
                  }
                  else if(res.status == 400)
                          setInvalid(true);
                  else setError(res);



                }}
              >

              <Form>
                <div className="mb-3">
                  {invalid && (
                    <div>
                      <p className="text-danger">Invalid Email or Password</p>
                    </div>
                  )}
                  <label className="form-label">Email</label>
                  <Field
                    name="email"
                    required
                    className="form-control"
                    placeholder="ex. name@gmail.com"
                    type="email"
                    />
                </div>

                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <Link className="float-end" to="/forgot-password/enter-email">
                    Forgot?
                  </Link>
                  <Field
                    className="form-control"
                    name="password"
                    required
                    minLength={8}
                    maxLength={20}
                    placeholder="******"
                    type="password"
                    />
                </div>

                <button
                  className="btn w-100 btn-primary"
                  type="submit"
                  >
                  Sign in
                </button>
              </Form>
              </Formik>

              <p className="text-divider my-4">New to Yunistore?</p>
              <Link to="/signup/enter-email" className="btn w-100 btn-light">
                Create new account
              </Link>
            </div>
          </div>
        </div>
      
      </div>
    </section>
  );
}

// export async function action({ request }) {
//   const formData = await request.formData();
//   const res = await fetch(host+"/auth/signin", {
//     method: "post",
//     body: formData,
//   });
  
//   const result = {
//     jwt: "",
//     err: "",
//   };

//   if (res.ok) result.jwt = await res.text();
//   else if (res.status == 400) result.err = "invalid email or password";
//   else throw res;

//   return result;
// }
