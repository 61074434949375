import { useEffect } from "react";
import { Outlet,  useOutletContext } from "react-router-dom";
import { host } from "../config";

export default function Signup() {
  
  const { setNavComp } = useOutletContext();

  useEffect(() => setNavComp("register"), []);

  const email = {endpoint: host+"/auth/signup/verify-email/email",
       to:"/signup/enter-otp"
};
const otp = {endpoint: host+"/auth/signup/verify-email/otp",
to: "/signup/user-details"     }               



return <Outlet context={{email, otp}} />
}
